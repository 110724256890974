import React from 'react'
import styled from 'styled-components'

import { Section, CSSGrid, Container, Col } from '../components/Grid'
import { StyledLink } from '../components/StyledLink'
import { Box } from '../components/Box'
import { designTokens } from '../components/Theme/designTokens'
import ImageIcon from '../components/ImageIcon'
import { ButtonLink } from '../components/Button'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ImageContainer = styled.div`
  display: inline-block;
  width: 104px;
  height: 104px;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <CSSGrid>
        <Col col={1/4} media={{ phone: 1 }}></Col>
        <Col col={1/2} media={{ phone: 1 }}>
          <Box center marginTop={designTokens.space[8]} marginBottom={designTokens.space[3]}>
            <ImageContainer>
              <ImageIcon srcName="grapa-example-icon"/>
            </ImageContainer>
            <h3>Oops!</h3>
            <p>Looks like there's nothing here. Try again or head back to the homepage.</p>
          </Box>
          <div style={{
            textAlign: 'center',
            marginBottom: designTokens.space[8]
          }}>
            <ButtonLink bg={({ theme }) => theme.green} to={'/'}>Back to the homepage</ButtonLink>
          </div>
        </Col>
        <Col col={1/4} media={{ phone: 1 }}></Col>
      </CSSGrid>
    </Container>
  </Layout>
)

export default NotFoundPage
